import { handleActions, createAction } from 'redux-actions';
import { KeyValueType, UniversalContactFilterState } from 'types';

const initialState = {
  districtId: -1,
  instituteId: -1,
  schoolId: -1,
  isTeacher: undefined,
};

export const setUniversalContactFilterValue = createAction(
  'setUniversalContactFilterValue'
);
export const resetUniversalContactFilterValue = createAction(
  'resetUniversalContactFilterValue'
);
export const clearUniversalContactFilters = createAction(
  'clearUniversalContactFilters'
);

export default handleActions<
  UniversalContactFilterState,
  KeyValueType<number> | string
>(
  {
    setUniversalContactFilterValue: (state, action) => {
      return {
        ...state,
        ...(action.payload as KeyValueType<number>),
      };
    },
    resetUniversalContactFilterValue: (state, action) => {
      return {
        ...state,
        [action.payload as string]: -1,
      };
    },
    clearUniversalContactFilters: (state, action) => {
      return initialState;
    },
  },
  initialState
);
