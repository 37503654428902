/**
 * Utility/helper methods for Universal Contacts
 * @module Utilities > UniversalcontactUtility
 * @author AmplifyCP
 */

import {
  UCStreetAddress,
  UCContactInfo,
  UCPersonalInfo,
  UniversalContact,
  School,
  Program,
  emptySchool,
} from 'types';
import { EMPTY_PLACEHOLDER } from 'constants/application.constants';
import {
  first,
  map,
  isEmpty,
  filter,
  includes,
  some,
  defaultTo,
  flatMap,
  unionBy,
  get,
} from 'lodash';
import { TeacherInfo } from 'types/universalcontact/TeacherInfo.types';
import { renderData } from './objectUtility';
import { isSuperAdmin } from './permissionsUtility';

/**
 * @param { UniversalContact } contact the contact to get the data for
 * @returns { string } The first district or institute name found otherwise an empty placeholder.
 * @function
 */
export const getDistrictInstituteDisplay = (
  contact: UniversalContact
): string => {
  if (contact.districts.length > 0) {
    return contact.districts[0].districtName;
  }
  if (contact.institutes.length > 0) {
    return contact.institutes[0].schoolName;
  }
  return EMPTY_PLACEHOLDER;
};

/**
 * @param { UniversalContact } contact the contact to get thee information from
 * @returns { string } The contact type display name for the contact
 * @function
 */
export const getContactTypeDisplay = (contact: UniversalContact): string => {
  if (contact.permissionRoles.length <= 0) {
    return EMPTY_PLACEHOLDER;
  }
  const primaryRole = contact.permissionRoles[0].permissionRoleName;
  switch (primaryRole) {
    case 'admin':
      return 'Administrator';

    case 'cccsprogramdirector':
      return 'CCCS Program Director';

    case 'programpoweruser':
      return 'Program Power User';

    case 'universalcontactpoweruser':
      return 'Contacts Power User';

    case 'primaryprogrammanager':
      return 'Primary Programs Contact';

    default:
      return EMPTY_PLACEHOLDER;
  }
};

/**
 * @function
 * @param { UniversalContact } contact the contact to test
 * @returns { boolean } bool indicating if the contact has a Universalcontact Power role or not
 */
export const isContactPowerRole = (contact: UniversalContact): boolean => {
  return some(
    map(contact.permissionRoles, (item) => {
      return includes(
        ['admin', 'universalcontactpoweruser'],
        item.permissionRoleName
      );
    })
  );
};

/**
 * @param { UniversalContact } contact the contact to test
 * @returns { boolean } bool indicating if the contact has an Admin role or not
 * @function
 */
export const isAdminRole = (contact: UniversalContact): boolean => {
  return some(
    map(contact.permissionRoles, (item) => {
      return includes(
        ['admin', 'cccsprogramdirector', 'perkinsplanmanager'],
        item.permissionRoleName
      );
    })
  );
};

/**
 * @param { UniversalContact } contact the contact to test
 * @returns { boolean } bool indicating if the contact has a Perkins Admin Role or not
 * @function
 */
export const isPerkinsAdminRole = (contact: UniversalContact): boolean => {
  return some(
    map(contact.permissionRoles, (item) => {
      return includes(['admin', 'perkinsplanmanager'], item.permissionRoleName);
    })
  );
};

/**
 * @param { UniversalContact } contact the contact to test
 * @returns { boolean } bool indicating if the contact has a Finance Admin Role or not
 * @function
 */
export const isFinanceApproverAdmin = (contact: UniversalContact): boolean => {
  return some(
    map(contact.permissionRoles, (item) => {
      return includes(['admin', 'financeapprover'], item.permissionRoleName);
    })
  );
};

/**
 * @param { UniversalContact } contact Universal Contact instance.
 * @returns { string } The first district name associated with the contact or null if there are no associated districts.
 * @function
 */
export const getContactDistrict = (
  contact: UniversalContact
): string | null => {
  const primary = filter(contact.districts, (item) => {
    return item.isPrimary === true;
  });

  if (primary && primary.length > 0) {
    return primary[0].districtName;
  } else if (contact.districts && contact.districts.length > 0) {
    return contact.districts[0].districtName;
  }
  return null;
};

/**
 * @param { UniversalContact } contact Universal Contact instance.
 * @returns { string } The first school name associated with the contact or null if there are no associated schools.
 * @function
 */
export const getContactSchool = (contact: UniversalContact): string | null => {
  const primary = filter(contact.schools, (item) => {
    return item.isPrimary === true;
  });

  if (primary && primary.length > 0) {
    return primary[0].schoolName;
  } else if (contact.schools && contact.schools.length > 0) {
    return contact.schools[0].schoolName;
  }
  return null;
};

/**
 * @param { UniversalContact } contact Universal Contact instance.
 * @returns { string } The first district or school name found otherwise an empty placeholder.
 * @function
 */
export const getContactDistrictSchool = (contact: UniversalContact): string => {
  if (!contact) {
    return EMPTY_PLACEHOLDER;
  }

  const district = getContactDistrict(contact);
  const school = getContactSchool(contact);
  const output = district ? district : school;

  return output ? output : EMPTY_PLACEHOLDER;
};

/**
 * @param { UniversalContact } contact Universal Contact instance
 * @returns { string } The full name of the contact.
 * @function
 */
export const getContactFullName = (contact: UniversalContact): string => {
  return renderData(
    `${get(contact, 'firstName', '')} ${get(contact, 'lastName', '')}`
  );
};

/**
 * @param { UniversalContact } contact Universal Contact instance
 * @returns { string } The contacts initials
 * @function
 */
export const getUserInitials = (contact: UniversalContact): string => {
  if (!contact) {
    return `${EMPTY_PLACEHOLDER}${EMPTY_PLACEHOLDER}`;
  }
  return `${
    contact.firstName ? contact.firstName.slice(0, 1) : EMPTY_PLACEHOLDER
  }${contact.lastName ? contact.lastName.slice(0, 1) : EMPTY_PLACEHOLDER}`;
};

/**
 * @param { UniversalContact } contact Universal Contact instance
 * @returns { string } The first non-empty email address for the contact
 * @function
 */
export const getUserEmail = (contact: UniversalContact): string => {
  const loginMail = contact.loginEmail && contact.loginEmail.trim();
  const otherMail =
    contact.universalContactEmail &&
    contact.universalContactEmail.length > 0 &&
    contact.universalContactEmail[0].email.trim();

  return loginMail || otherMail || EMPTY_PLACEHOLDER;
};

/**
 * @param { UniversalContact } contact Universal Contact instance
 * @returns { string } returns the first school or institute name associated with the contact
 * @function
 */
export const getUserFirstSchoolName = (contact: UniversalContact): string => {
  const school = defaultTo(first(contact.schools), undefined);
  const institute = defaultTo(first(contact.institutes), emptySchool());

  return school ? school.schoolName : institute.schoolName;
};

/**
 * @param { UniversalContact } contact Universal Contact instance
 * @returns { string } the first non-empty phone number for the contact
 * @function
 */
export const getUserPhone = (contact: UniversalContact): string => {
  const phone = first(contact.universalContactPhone);
  if (phone) {
    return phone.number.trim()
      ? _getUserPhoneHelper(phone.number.trim())
      : EMPTY_PLACEHOLDER;
  }
  return EMPTY_PLACEHOLDER;
};

/**
 * @todo Replace this or the formatPhone function
 * @param {string } phone a phone number in string format
 * @returns { string } the phone number formatted like xxx-xxx-xxxx or an empty-placeholder
 * @function
 */
export const _getUserPhoneHelper = (phone: string) => {
  const digits = phone.replace(/\D/g, '');
  const match = digits.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]} - ${match[3]}`;
  }
  return EMPTY_PLACEHOLDER;
};

/**
 * @todo Replace this or the _getUserPhoneHelper function
 * @param {string } phone a phone number in string format
 * @returns { string } the phone number formatted like xxx-xxx-xxxx
 * @function
 */
export const formatPhone = (phone: string) => {
  const digits = phone.replace(/\D/g, '');
  const match = digits.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]} - ${match[3]}`;
  }
  return phone;
};

/**
 * @param { TeacherInfo } teacherInfo object to get the credentials from.
 * @returns { string } Formatted teachers credential number or message stating they are not a teacher
 * @function
 */
export const getCredentialString = (teacherInfo: TeacherInfo): string => {
  if (!teacherInfo.isTeacher) {
    return 'Contact is not a teacher';
  } else if (isEmpty(teacherInfo.credentialId)) {
    return 'No credentials on record';
  } else if (!teacherInfo.credentialExpirationDate) {
    return `CN: ${teacherInfo.credentialId} | EXPIRATION NOT SET`;
  }
  return `CN: ${teacherInfo.credentialId} | EXP: ${new Date(
    teacherInfo.credentialExpirationDate
  ).toLocaleDateString()}`;
};

/**
 * @param { UniversalContact } contact to extract the teacher information from .
 * @returns { TeacherInfo } TeacherInfo data for the specified contact.
 * @function
 */
export const extractTeacherInfo = (contact: UniversalContact): TeacherInfo => {
  return {
    isTeacher: contact.isTeacher,
    credentialExpirationDate: contact.credentialExpirationDate,
    credentialId: contact.credentialId,
  };
};

/**
 * @todo use get(contact,'street1',') etc...  for safety
 * @param { UniversalContact } contact Universal Contact instance
 * @returns { UCStreetAddress } address information from the contact
 * @function
 */
export const extractAddress = (contact: UniversalContact): UCStreetAddress => {
  return {
    street1: contact.street1,
    street2: contact.street2,
    city: contact.city,
    state: contact.state,
    zipCode: contact.zipCode,
  };
};

/**
 * @todo use get(contact,'firstName',') etc...  for safety
 * @param { UniversalContact } contact Universal Contact instance
 * @returns { UCPersonalInfo } personal information from the contact
 * @function
 */
export const extractPersonal = (contact: UniversalContact): UCPersonalInfo => {
  return {
    firstName: contact.firstName,
    middleName: contact.middleName,
    lastName: contact.lastName,
    title: contact.title,
    loginEmail: contact.loginEmail,
  };
};

/**
 * @param { UniversalContact } contact Universal Contact instance
 * @returns { UCContactInfo } contact information from the contact
 * @function
 */
export const extractContactInfo = (
  contact: UniversalContact
): UCContactInfo => {
  return {
    universalContactEmail: contact.universalContactEmail,
    universalContactPhone: contact.universalContactPhone,
    fax: contact.fax,
  };
};

/**
 * Removes schools from districts
 * Removes departments from institutes
 * Removes universalContactId and  isActive from the payload
 * Puts all emails in lower case
 * @param { UniversalContact } contact Universal Contact instance
 * @returns { UniversalContact } UniversalContact that has been sanitized for use with api
 * @function
 * @todo This really should be removed, the API should handle this
 */
export const sanitizeContactForAPI = (
  contact: UniversalContact
): UniversalContact => {
  const universalContactEmail = contact.universalContactEmail.map((v) => {
    return {
      ...v,
      email: v.email.toLowerCase(),
    };
  });
  const districts = map(contact.districts, (item) => {
    return {
      ...item,
      schools: [],
    };
  });
  const institutes = map(contact.institutes, (item) => {
    return {
      ...item,
      departments: [],
    };
  });
  return {
    ...contact,
    credentialId: contact.credentialId.toLowerCase(),
    districts,
    institutes,
    loginEmail: contact.isAadB2c ? contact.loginEmail.toLowerCase() : '',
    universalContactEmail,
  };
};

/**
 * @param { UniversalContact } loggedInUser the currently logged in user
 * @param { UniversalContact } contact the user about to be edited
 * @return { boolean } True if the user can edit the contact otherwise false
 * @function
 */
export const canEditUser = (
  loggedInUser: UniversalContact,
  contact: UniversalContact
) => {
  if (loggedInUser.universalContactId === contact.universalContactId) {
    return true;
  }

  if (isSuperAdmin(contact) && !isSuperAdmin(loggedInUser)) {
    return false;
  }

  const userDistricts: number[] = [
    ...getContactDistricts(loggedInUser),
    ...getDistrictsFromSchools(loggedInUser.schools),
  ];
  const contactDistricts: number[] = [
    ...getContactDistricts(contact),
    ...getDistrictsFromSchools(contact.schools),
  ];

  const userInstitutes: number[] = getContactInstitutes(loggedInUser);
  const contactInstitutes: number[] = getContactInstitutes(contact);

  const districtResults = some(userDistricts, (item) => {
    return includes(contactDistricts, item);
  });

  const instituteResults = some(userInstitutes, (item) => {
    return includes(contactInstitutes, item);
  });

  return some([districtResults, instituteResults, isSuperAdmin(loggedInUser)]);
};

/**
 * @param { School[] } schools An array of schools to test
 * @returns { number[] } An array of districtIds associated with the schools array
 * @function
 */
export const getDistrictsFromSchools = (schools: School[]): number[] => {
  return map(schools, (item) => {
    return item.districtId;
  });
};

/**
 * @param { UniversalContact } contact Universal Contact instance
 * @returns { number[] } An array of districtIds associated with the contact
 * @function
 */
export const getContactDistricts = (contact: UniversalContact): number[] => {
  return map(contact.districts, (item) => {
    return item.districtId;
  });
};

/**
 * @param { UniversalContact } contact Universal Contact instance
 * @returns { number[] } An array of instituteIds (schoolIds) associated with the contact
 * @function
 */
export const getContactInstitutes = (contact: UniversalContact): number[] => {
  return map(contact.institutes, (item) => {
    return item.schoolId;
  });
};

/**
 * @param { UniversalContact } contact the contact to check
 * @param { Program } program the program to check
 * @returns { boolean } indicating if the user can edit the program or not.
 * @function
 */
export const canContactEditProgram = (
  contact: UniversalContact,
  program: Program
) => {
  const inInstitutes = some(
    map(contact.institutes, (item) => {
      return item.schoolId === program.schoolId;
    })
  );

  const inSchools = some(
    map(contact.schools, (item) => {
      return item.schoolId === program.schoolId;
    })
  );

  const inDistrict = some(
    map(contact.districts, (item) => {
      return item.districtId === program.districtId;
    })
  );

  const inDistrictSchool = some(
    map(contact.districts, (item) => {
      return some(
        map(item.schools, (school) => {
          return school.schoolId === program.schoolId;
        })
      );
    })
  );

  return some([inSchools, inDistrict, inInstitutes, inDistrictSchool]);
};

/**
 * Check if the user associated by the email is the current user or not
 * @param { UniversalContact? } contact
 * @param { number } universalContactId
 * @returns { boolean } True or False
 * @function
 */
export const isNotCurrentUser = (
  contact: UniversalContact | undefined,
  universalContactId: number
) => {
  if (contact && 'universalContactId' in contact) {
    return contact.universalContactId !== universalContactId;
  }
  return false;
};

/**
 * @param { UniversalContact } user the logged in user
 * @returns { School[] } List of Schools associated with the user.
 * @function
 */
export const getActiveUserSchools = (user: UniversalContact) => {
  const districtSchools = flatMap(user.districts, (district) => {
    return district.schools ? [...district.schools] : [];
  });
  const schoolsa = unionBy(districtSchools, user.schools, 'schoolId');
  return unionBy(schoolsa, user.institutes, 'schoolId');
};

/**
 * @param {string | null} ssn The ssn to format
 * @return { string } The formatted SSN
 * @function
 */
export const formatSSN = (ssn: string | null): string => {
  if (ssn === null) return '';
  const digits = ssn.replace(/\D/g, '');
  const match = digits.match(/^(\d{3})(\d{2})(\d{4})$/);
  if (match) {
    return `${match[1]} - ${match[2]} - ${match[3]}`;
  }
  return ssn;
};
