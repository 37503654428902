import React from 'react';
import { useHistory } from 'react-router';
import { map, some } from 'lodash';
import { FlexGroup, FlexRow } from 'layouts';
import {
  getContactFullName,
  getUserEmail,
  getUserPhone,
} from 'util/universalcontact.utility';
import { EMPTY_PLACEHOLDER } from 'constants/application.constants';
import { Consortium, ConsortiumMember, UniversalContact } from 'types';
import Button from 'components/Common/FormElements/Button';
import Icon from 'components/Common/Icons';
import Paper from 'components/Common/Paper';
import Loading from 'components/Common/Loading';
import { contactHasRoles } from 'util/permissionsUtility';
import SecureWrap from 'components/Common/Authorization/SecureWrap';

type ConsortiumViewProps = {
  consortium: Consortium;
  fiscalAgentUser: UniversalContact;
  isFetching: boolean;
  loggedInUser: UniversalContact;
};

export const ConsortiumViewComponent: React.FC<ConsortiumViewProps> = (
  props
) => {
  const { consortium, fiscalAgentUser, isFetching, loggedInUser } = props;
  const history = useHistory();

  const urls: any = {
    back: '/grants/consortium',
    edit: `/grants/consortium/add-edit/${consortium.consortiumId}/${
      consortium.fiscalYear.year
    }`,
  };

  const renderData = (value: string) => {
    return value ? value : EMPTY_PLACEHOLDER;
  };

  const getFiscalYear = () => {
    if (consortium.fiscalYear.startDate && consortium.fiscalYear.endDate) {
      const startYear = new Date(consortium.fiscalYear.startDate).getFullYear();
      const endYear = new Date(consortium.fiscalYear.endDate).getFullYear();
      const currYear = new Date(Date.now()).getFullYear();
      if (currYear - endYear > 20) return;
      return `${startYear}${EMPTY_PLACEHOLDER}${endYear}`;
    }
  };

  const isEditable = () => {
    return some([
      consortium.editable,
      contactHasRoles(loggedInUser, ['admin', 'perkinsplanmanager']),
    ]);
  };

  return (
    <React.Fragment>
      <SecureWrap component="grantsconsortia">
        <Loading isActive={isFetching} messageBefore="Loading consortium..." />
        <Paper>
          <FlexRow>
            <FlexGroup>
              <h2 className="u-section-title-text">
                {renderData(consortium && consortium.consortiumName)}
              </h2>
              <div className="consortium-view__status">
                <Icon
                  name="circleFilled"
                  className={
                    consortium && consortium.status
                      ? 'consortium-view__status-icon consortium-view__status-icon--active'
                      : 'consortium-view__status-icon consortium-view__status-icon--inactive'
                  }
                />
                <p className="consortium-view__status-text">
                  {consortium && consortium.status ? 'Active' : 'Inactive'}
                </p>
              </div>
            </FlexGroup>
          </FlexRow>
        </Paper>

        <Paper>
          <FlexRow>
            <h2 className="u-section-title-text">Details</h2>
          </FlexRow>
          <FlexRow>
            <FlexGroup>
              <label className="form__label">Consortium Name</label>
              <p className="input-display">{consortium.consortiumName}</p>
            </FlexGroup>
            <FlexGroup>
              <label className="form__label">Last Modified Date</label>
              <p className="input-display">
                {renderData(
                  new Date(consortium.modifiedDate).toLocaleDateString()
                )}
              </p>
            </FlexGroup>
            <FlexGroup>
              <label className="form__label">Fiscal Year</label>
              <p className="input-display">
                {getFiscalYear() ? getFiscalYear() : 'No Date on Record'}
              </p>
            </FlexGroup>
          </FlexRow>

          <FlexRow>
            <FlexGroup>
              <label className="form__label">Institution Code</label>
              <p className="input-display">
                {renderData(consortium.fiscalAgent.institutionCode)}
              </p>
            </FlexGroup>
            <FlexGroup>
              <label className="form__label">Vendor Code</label>
              <p className="input-display">
                {renderData(consortium.fiscalAgent.vendorCode)}
              </p>
            </FlexGroup>
            <FlexGroup>
              <label className="form__label">DUNS Number</label>
              <p className="input-display">
                {renderData(consortium && consortium.fiscalAgent.duns)}
              </p>
            </FlexGroup>
          </FlexRow>
          <FlexRow>
            <FlexGroup>
              <label className="form__label">Fiscal Agent Name</label>
              <p className="input-display">
                {getContactFullName(fiscalAgentUser)}
              </p>
            </FlexGroup>
            <FlexGroup>
              <label className="form__label">Fiscal Agent Phone</label>
              <p className="input-display">{getUserPhone(fiscalAgentUser)}</p>
            </FlexGroup>
            <FlexGroup>
              <label className="form__label">Fiscal Agent Email</label>
              <p className="input-display">{getUserEmail(fiscalAgentUser)}</p>
            </FlexGroup>
          </FlexRow>

          <FlexRow>
            <FlexGroup>
              <label className="form__label">Members in this Consortium</label>
              {consortium.members.length === 0 && (
                <p>There are no members in this consortium</p>
              )}
              {map(consortium.members, (m: ConsortiumMember) => {
                return (
                  <div className="u-flex" key={m.id}>
                    <div style={{ width: '8rem' }}>{m.type}:</div>
                    <div className="u-glex-grow-1">{m.name}</div>
                  </div>
                );
              })}
            </FlexGroup>
          </FlexRow>
        </Paper>
        <Paper>
          <div className="form__btn-container">
            <FlexRow>
              <FlexGroup>
                <Button
                  text="Return to Consortium Management Dashboard"
                  onClick={() => history.push(urls.back)}
                  className="form__btn--cancel"
                />
              </FlexGroup>
              <SecureWrap component="grantsconsortia" requireEdit={true}>
                <FlexGroup>
                  <Button
                    disabled={!isEditable()}
                    text="Edit This Consortium"
                    onClick={() => history.push(urls.edit)}
                    className="form__btn--submit"
                  />
                </FlexGroup>
              </SecureWrap>
            </FlexRow>
          </div>
        </Paper>
      </SecureWrap>
    </React.Fragment>
  );
};
